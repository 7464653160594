import { HeaderContainer, Image } from './Header.styled';

import logo from 'logo.svg';

interface Props {
  title: string;
}

const Header: React.FC<Props> = ({ title }) => (
  <HeaderContainer>
    <Image alt="Kiski Group, LLC" src={logo} />
    <h1>{title}</h1>
    <div />
  </HeaderContainer>
);

export default Header;
