import { colors } from '@hummingbird/shared';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  color: ${colors.navy};
  text-transform: uppercase;
  background-color: ${colors.white};
`;
