import { ChevronRight, IconButton } from '@hummingbird/shared';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  to: string;
}

const ActionButton: React.FC<Props> = ({ to }) => {
  const navigate = useNavigate();

  const handleOnClick = useCallback(() => {
    navigate(to);
  }, [navigate, to]);

  return (
    <IconButton
      icon={ChevronRight}
      label="Actions"
      variant="simple"
      onClick={handleOnClick}
    />
  );
};

export default ActionButton;
