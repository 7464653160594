import { Container, Content } from './ProtectedLayout.styled';

import Header from 'components/Header/Header';
import NavBar from 'components/NavigationBar/NavigationBar';

interface Props {
  children: JSX.Element;
}

const TITLE = 'KPS-HDS Admin Console';

const ProtectedLayout: React.FC<Props> = ({ children }) => (
  <Container>
    <Header title={TITLE} />
    <NavBar />
    <Content>{children}</Content>
  </Container>
);

export default ProtectedLayout;
