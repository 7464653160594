import { fontSizes, fontWeights } from '@hummingbird/shared';
import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: 16px;
  font-weight: ${fontWeights.mediumLarge};
  font-size: ${fontSizes.xl};
`;
