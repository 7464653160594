import { ChipButton, Plus } from '@hummingbird/shared';
import { styled } from 'styled-components';

export const GrantAccessButton = styled(ChipButton).attrs({
  style: {
    height: 28,
  },
  iconPosition: 'left',
  icon: Plus,
})``;
