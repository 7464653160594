import { colors, fontSizes, fontWeights, zIndexes } from '@hummingbird/shared';
import { Content, Arrow } from '@radix-ui/react-tooltip';
import styled from 'styled-components';

export const TooltipContent = styled(Content)`
  z-index: ${zIndexes.tooltip};
  padding: 12px 16px;
  color: ${colors.navy};
  font-weight: ${fontWeights.regular};
  font-size: ${fontSizes.xs};
  background: ${colors.white};
  border-radius: 12px;
  box-shadow: 3px 3px 15px ${colors.quartz};
`;

export const TooltipArrow = styled(Arrow)`
  width: 16px;
  height: 8px;
  fill: ${colors.white};
`;
